import Badge from '@wander/ui/src/components/Badge';

import Icon from '@wander/ui/src/components/Icon';
import getLocation from '../../actions/getLocation';

type Props = {
  property: {
    name: string;
    bookable: boolean;
    isNew?: boolean;
    isOpenToOffersSoon?: boolean;
    address: {
      city: string;
      state: string;
    };
  };
};

const Property_Info = ({ property }: Props) => {
  return (
    <>
      <div className='text-property-eyebrow text-white/60'>{getLocation(property)}</div>
      <div className='mb-0.5 flex items-center gap-2 font-medium'>
        {property.name}

        {!property.bookable ? (
          <Badge className='bg-w-8002 text-w-4004'>Coming soon</Badge>
        ) : property.isNew ? (
          <Badge className='bg-[#2D3128] text-[#D1EAB3]'>New</Badge>
        ) : property.isOpenToOffersSoon ? (
          <Badge className='bg-w-8002 text-w-3005'>
            <Icon name='dollar-badge' className='h-3 w-3 top-0' />
            Make an offer
          </Badge>
        ) : null}
      </div>
    </>
  );
};

export default Property_Info;
